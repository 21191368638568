import cx from "classnames";
import { graphql, Link } from "gatsby";
import React, { FC } from "react";
import { Newsletter } from "../components/newsletter";

import SEO from "../components/seo";
import * as global from "../styles/global.module.scss";
import * as styles from "../styles/pages/votre-guide-personnalise.module.scss";

const { routes } = require("../routes");

interface Props {
  data: {
    [key: string]: any;
  };
  location: Location;
}

export const GuidePersoPageQuery = graphql`
  query {
    titleBgImg: file(relativePath: { eq: "montage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const GuidePersoPage: FC<Props> = (props: Props) => {
  const { data, location } = props;
  return (
    <>
      <SEO
        meta={[
          {
            name: "og:url",
            content: location.href,
          },
        ]}
        title="Constant - Votre guide personnalisé de marques éthiques"
      />
      <div className={global.pageWrapper}>
        <div className={cx(styles.intro, global.intro)}>
          <p className={global.text}>
            <strong>
              Je fais une pause pour le moment&nbsp;!
              <br />
              Cette offre n'est donc plus disponible.
            </strong>
            <br />
            <Link to={routes.app.guides.path} className={global.link}>
              Retrouvez les guides ready-to-go
            </Link>
          </p>
        </div>

        {/* <div className={styles.titleBgContainer}>
          <div className={styles.titleBgContent}>
            <div className={styles.titleCtaContainer}>
              <div className={styles.titleContainer}>
                <h2 className={cx(styles.title, global.title)}>
                  Votre guide personnalisé&nbsp;: Trouvez votre vêtement ou
                  découvrez des marques éthiques (55 €)
                </h2>
              </div>
              <div className={styles.ctaContainer}>
                <NavCta
                  to={routes.app.parcours.demande.guidePerso.path}
                  label="Je veux un guide personnalisé"
                  wordingHoverClassname={styles.wordingHoverCta}
                  onClick={() => {
                    sendGA("click", {
                      event_label: "Commencer une demande - header",
                    });
                  }}
                />
              </div>
            </div>
            <div className={styles.bgContainer}>
              <AnimatedElement translateY={{ speed: -26 }}>
              <div ref={React.createRef()}>
                <GatsbyImage
                  image={data.titleBgImg.childImageSharp.gatsbyImageData}
                  alt="ok"
                />
              </div>
              </AnimatedElement>
            </div>
          </div>
        </div> */}

        {/* <div className={styles.textContentContainer}>
          <div className={global.text}>
            <p>
              <strong>Comment ça marche&nbsp;?</strong>
            </p>
            <ol>
              <li>
                Vous faites votre demande{" "}
                <strong>en moins de 2min, gratuitement</strong>.
              </li>
              <li>
                Je la valide et vous contacte (par téléphone ou sms) pour être
                certain de bien avoir cerné votre style et ce que vous cherchez.
              </li>
              <li>
                Vous confirmez votre demande en procédant au paiement du guide
                (55€), grâce à un lien que je vous enverrai pas email.
              </li>
              <li>
                Je trouve les marques qui matchent le plus avec votre demande et
                je construis votre guide personnalisé.
              </li>
              <li>
                <strong>48h plus tard</strong>, vous recevez votre guide
                personnalisé direct dans votre boîte mail&nbsp;!
              </li>
            </ol>
            <p>
              Dedans vous trouverez, 3 marques qui proposent le vêtement en
              version éthique que vous recherchez et 2 marques bonus qui
              correspondent à votre style&nbsp;! <br />
              Et hop&nbsp;! Vous avez trouvé votre vêtement en version éthique
              et en plus vous découvrez 5 marques éco-responsables que vous ne
              connaissiez pas.
            </p>
            <p>
              <strong>Bonus</strong>&nbsp;: Vous pourrez partager vos bons plans
              mode éthique à votre entourage et faire une bonne action, tout en
              montrant que vous êtes calé. Win win.
            </p>
            <p>
              <strong>Grâce à cette session :</strong>
            </p>
            <ol>
              <li>
                Les marques de fast-fashion ne vous traverseront même plus
                l'esprit. Vous repartez avec 5 références éco-responsables qui
                correspondent à votre style et à vos valeurs.
              </li>
              <li>
                Les bases de la mode éco-responsable n'auront plus de secrets
                pour vous. Si vous n’êtes pas familier avec ce qui rend la mode
                plus éco-responsable, cette session vous permettra d'avoir les
                premières connaissances.Si vous vous y connaissez déjà, je vous
                apporterai des informations plus poussées&nbsp;!
              </li>
              <li>
                Je reste à dispo pour toutes les questions que vous pourriez
                avoir, même lorsque vous aurez reçu votre guide&nbsp;! Vous avez
                mon numéro, autant qu'il serve&nbsp;!
              </li>
            </ol>
          </div>
          <div className={styles.ctaContainer}>
            <NavCta
              to={routes.app.parcours.demande.guidePerso.path}
              label="Je veux un guide personnalisé"
              wordingHoverClassname={styles.wordingHoverCta}
              onClick={() => {
                sendGA("click", {
                  event_label: "Commencer une demande - body",
                });
              }}
            />
          </div>

          <div className={global.text}>
            <p>Questions fréquentes :</p>

            <ul>
              <li>
                <p className={styles.question}>
                  Je ne cherche pas de vêtement en particulier, est-ce que la
                  formule marche aussi pour moi&nbsp;?
                </p>
                <p>
                  Tout à fait&nbsp;! L'avantage de ce guide, c'est que je peux
                  l'adapter à votre demande précise. <br /> Je peux donc créé le
                  guide avec uniquement des marques qui correspondent à votre
                  style de manière générale. Toujours dans le but que vous ayez
                  des références éco-reponsables pour vos futurs besoins
                  shopping&nbsp;!
                </p>
                <NavCta
                  to={routes.app.parcours.decouverte.step2a.path}
                  label="Guide découverte"
                  wordingHoverClassname={styles.wordingHoverCta}
                  onClick={() => {
                    sendGA("click", {
                      event_label: "guide découverte",
                    });
                  }}
                />
              </li>
            </ul>
          </div>
        </div> */}
        {/* <Newsletter /> */}
      </div>
    </>
  );
};

export default GuidePersoPage;
